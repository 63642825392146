var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"practice-box"},[_c('el-dialog',{attrs:{"title":this.$t('message.notice'),"visible":_vm.showLeaveAlert,"width":"30%","close-on-click-modal":false,"center":""},on:{"update:visible":function($event){_vm.showLeaveAlert=$event}}},[_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('Tests.leaveAlert', {
            counts: this.leaveCountText,
            times: this.leaveSeconds,
            second: this.second
          })
        )}})]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"default","size":"small"},on:{"click":function($event){_vm.showLeaveAlert = false}}},[_vm._v(" "+_vm._s(this.$t("message.continue"))+" ")])],1)]),(_vm.questions)?_c('QuestionWrapper',{ref:"QuestionWrapper",attrs:{"examineeName":_vm.examineeName,"testType":_vm.practice.name,"questionOrder":_vm.questionOrder,"sectionOrder":_vm.sectionOrder,"page":_vm.page,"questions":_vm.questions,"questionCount":_vm.questions.length,"examTimeSeconds":_vm.testTime,"canHighlighter":_vm.canHighlighter,"canEliminator":_vm.canEliminator,"canMasking":_vm.canMasking},on:{"onCanHighlighter":function($event){_vm.canHighlighter = !_vm.canHighlighter},"onCanEliminator":function($event){_vm.canEliminator = !_vm.canEliminator},"onCanMasking":function($event){_vm.canMasking = !_vm.canMasking},"onBackQuestion":_vm.onBackQuestion,"onNextQuestion":_vm.onNextQuestion,"onSubmitSection":_vm.onSubmitSection,"onBackPage":function($event){_vm.page--},"onNextPage":function($event){_vm.page++},"onOrderQuestion":_vm.onOrderQuestion,"onExitTest":_vm.onExitTest,"onClearHighlight":_vm.onClearHighlight,"onChangeFlag":_vm.onChangeFlag}},[[(_vm.page === 1)?[_c('TestDirections1',{attrs:{"testType":_vm.practice.name}})]:_vm._e(),(_vm.page === 2)?[_c('TestDirections2',{attrs:{"testType":_vm.practice.name}})]:_vm._e(),(_vm.page === 3)?[(!_vm.loading)?_c('MultipleChoiceWithPassage',{ref:"MultipleChoiceWithPassage",attrs:{"id":_vm.questionOrder,"testType":_vm.practice.name,"canHighlighter":_vm.canHighlighter,"canEliminator":_vm.canEliminator,"canMasking":_vm.canMasking,"passageContent":_vm.getPassage,"questionOrder":_vm.questionOrder,"content":_vm.question.question.content,"options":_vm.question.question.options,"answer":_vm.question.answer},on:{"onChangeAnswer":_vm.onChangeAnswer}}):_vm._e()]:_vm._e()]],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }