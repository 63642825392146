import { render, staticRenderFns } from "./MultipleChoiceWithPassage.vue?vue&type=template&id=3ef331da&scoped=true"
import script from "./MultipleChoiceWithPassage.vue?vue&type=script&lang=js"
export * from "./MultipleChoiceWithPassage.vue?vue&type=script&lang=js"
import style0 from "./MultipleChoiceWithPassage.vue?vue&type=style&index=0&id=3ef331da&prod&lang=css"
import style1 from "./MultipleChoiceWithPassage.vue?vue&type=style&index=1&id=3ef331da&prod&scoped=true&lang=css"
import style2 from "@/views/act/assets/css/act.css?vue&type=style&index=2&id=3ef331da&prod&scoped=true&lang=css&external"
import style3 from "@/views/act/assets/css/simplebar.css?vue&type=style&index=3&id=3ef331da&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ef331da",
  null
  
)

export default component.exports