<template>
  <div class="act-box">
    <el-dialog
      title="Question ID List"
      :visible.sync="showQuestionsDialog"
      fullscreen
    >
      <el-table :data="questions" height="720px" stripe @row-click="clickRow">
        <el-table-column type="index" label="#" width="50"> </el-table-column>
        <el-table-column prop="date" label="State">
          <template slot-scope="scope">
            <b class="text-success" v-if="scope.row.answer">
              Answered
            </b>
            <span v-else>
              Unanswered
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Flag">
          <template slot-scope="scope">
            <b class="text-success" v-if="scope.row.isMarked">
              <div class="bi-flag-fill"></div>
            </b>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <div class="wrapper">
      <header class="top-header">
        <nav class="navbar navbar-expand">
          <div class="left-topbar d-flex">
            <div class="section-name">
              <!-- {{ testType }} -->
              <img src="../../assets/ACTLOGO.png" alt="">
            </div>
          </div>
          <div class="mid-bar">
            <button
              v-if="page < 3"
              type="button"
              :class="
                page === 1 ? 'is-disabled btn md-button' : 'btn md-button'
              "
              @click="changePage('back')"
              :disabled="page === 1"
            >
              Prev
            </button>
            <button
              v-if="page === 3"
              type="button"
              class="btn md-button"
              @click="changeQuestion('back')"
            >
              Prev
            </button>
            <button
              type="button"
              :class="
                page < 3
                  ? 'is-disabled btn nav-button md-button'
                  : 'btn nav-button md-button'
              "
              data-toggle="modal"
              data-target="#navsection"
              :disabled="page < 3"
              @click="showQuestionsDialog = true"
            >
              Nav
            </button>
            <button
              v-if="page < 3"
              type="button"
              class="btn md-button"
              @click="changePage('next')"
            >
              Next
            </button>
            <template v-if="page === 3">
              <button
                v-if="questionOrder === questionCount"
                type="button"
                class="btn md-button"
                @click="submitSection"
              >
                Next Section
              </button>
              <button
                v-else
                type="button"
                class="btn md-button"
                @click="changeQuestion('next')"
              >
                Next
              </button>
            </template>
          </div>
          <!-- <div class="section-name">
             {{ testType }}
          </div> -->
          <div class="right-topbar ml-auto">
            <div class="dropdown tools">
              <button
                class="btn md-button dropdown-toggle"
                type="button"
                id="toolbutton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="toolbutton"
              >
                <!-- <a class="dropdown-item disabled" href="#"> Magnifier</a> -->
                <span
                  id="highlight"
                  class="dropdown-item"
                  @click="setHighlighter()"
                >
                  <span v-show="canHighlighter">
                    <i class="fa fa-check"></i>
                  </span>
                  Highlighter
                </span>
                <span
                  id="eliminator"
                  class="dropdown-item "
                  @click="setEliminator()"
                >
                  <span v-show="canEliminator">
                    <i class="fa fa-check"></i>
                  </span>
                  Answer Eliminator
                </span>
                <span id="masking" class="dropdown-item" @click="setMasking()">
                  <span v-show="canMasking">
                    <i class="fa fa-check"></i>
                  </span>
                  Answer Masking
                </span>
              </div>
            </div>
            <div class="btn-group" role="group">
              <button
                type="button"
                id="md-zoom-in"
                class="btn md-button"
                @click="zoomIn()"
              >
                <i class="bi-zoom-in" style="font-size: .6rem;"></i>
              </button>
              <button
                type="button"
                id="md-zoom-refresh"
                class="btn md-button"
                @click="zoomRefresh()"
              >
                <i class="bi-arrow-clockwise" style="font-size: .6rem;"></i>
              </button>
              <button
                type="button"
                id="md-zoom-out"
                class="btn md-button"
                @click="zoomOut()"
              >
                <i class="bi-zoom-out" style="font-size: .6rem;"></i>
              </button>
            </div>
            <button
              type="button"
              class="btn md-button clr-button"
              id="clearHighlight"
              @click="$emit('onClearHighlight')"
            >
              <span class="normal">Clear Highlight</span>
            </button>
            <button
              @click="showEndDialog = true"
              type="button"
              :class="
                page < 2
                  ? 'btn md-button bi-x end-button is-disabled'
                  : 'btn md-button bi-x end-button'
              "
              data-toggle="modal"
              data-target="#endsection"
              :disabled="page < 2"
            >
              End Section
            </button>
            <button type="button" class="btn md-button" id="timer">
              <span v-show="page < 2">
                <i class="fa fa-clock"></i>
              </span>
              <span v-show="page > 1">
                {{ instant.formatSecondToMMSS(remainExamTimeSeconds) }}
              </span>
            </button>
            <div class="dropdown">
              <button
                type="button"
                class="btn md-button"
                id="userbutton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="bi-person" style="font-size: 1.2rem;"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="userbutton"
              >
                <span class="dropdown-item" href="/exam/profile.php">
                  {{ examineeName }}
                </span>
                <div class="dropdown-divider mb-0"></div>
                <router-link
                  class="dropdown-item"
                  :to="{
                    name: 'ACTList'
                  }"
                >
                  Return
                </router-link>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div class="content">
        <slot />
       </div>
      <div class="footer">
        <template v-if="page === 3">
          <div class="pc-footer">
            <button
              type="button"
              disabled="disabled"
              class="btn is-disabled flowright ft-button"
              @click="questionRight"
            >
              &lt;
            </button>
            <!-- <button type="button" class="btn ft-button is-disabled">Info</button>
            <button type="button" class="btn ft-button">Instr</button> -->

            <ul class="pagination pagination-sm">
              <li
                v-for="(question, index) in questions"
                :key="index"
                :id="index"
                @click="changeQuestionWithOrder(index + 1)"
                :class="[
                  { 'page-item': true },
                  { active: index + 1 == questionOrder },
                  { 'bi-flag': question.isMarked }
                ]"
              >
                <a class="page-link">
                  {{ index + 1 }}
                </a>
              </li>
              <li class="page-item"></li>
            </ul>
            <button
              @click="questionLeft"
              type="button"
              class="btn flowleft ft-button"
            >
              &gt;
            </button>
            <button
              @click="onChangeFlag"
              type="button"
              class="btn ft-button flag-button bi-flag"
            >
              Flag
            </button>
          </div>
          <div class="mb-footer">
            <ul class="mb-pagination">
              <li
                v-for="(question, index) in questions"
                :key="index"
                :id="index"
                @click="changeQuestionWithOrder(index + 1)"
                :class="[
                  { 'page-item': true },
                  { active: index + 1 == questionOrder },
                  { 'bi-flag': question.isMarked }
                ]"
              >
                <a class="page-link">
                  {{ index + 1 }}
                </a>
              </li>
              <li class="page-item"></li>
            </ul>
          </div>
        </template>
      </div>
    </div>
    <el-dialog title="End Section" :visible.sync="showEndDialog" width="30%">
      <p>To end the section, press the End Section button.</p>
      <el-alert type="warning" show-icon :closable="false">
        <span slot="title">
          There are {{ getUnansweredCount() }} unanswered question(s).
        </span>
      </el-alert>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitSection">End Section</el-button>
        <el-button @click="showEndDialog = false">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import $ from "jquery";
import { instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: `Question ${this.questionOrder} - ` + this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: {
    testType: {
      type: String,
      default: "English",
      validator: value =>
        ["English", "Math", "Reading", "Science"].includes(value)
    },
    canHighlighter: {
      type: Boolean,
      default: false
    },
    canEliminator: {
      type: Boolean,
      default: false
    },
    canMasking: {
      type: Boolean,
      default: false
    },
    questions: {
      type: Array,
      default: () => [
        {
          answer: undefined,
          isMarked: false
        },
        {
          answer: undefined,
          isMarked: true
        },
        {
          answer: "A",
          isMarked: true
        },
        {
          answer: undefined,
          isMarked: true
        },
        {
          answer: undefined,
          isMarked: true
        },
        {
          answer: undefined,
          isMarked: true
        },
        {
          answer: undefined,
          isMarked: true
        },
        {
          answer: undefined,
          isMarked: true
        },
        {
          answer: undefined,
          isMarked: true
        },
        {
          answer: undefined,
          isMarked: true
        },
        {
          answer: undefined,
          isMarked: true
        },
        {
          answer: undefined,
          isMarked: true
        }
      ]
    },
    page: {
      type: Number,
      default: 1
    },
    sectionOrder: {
      type: Number,
      default: 1
    },
    sectionName: {
      type: String,
      default: ""
    },
    examineeName: {
      type: String,
      default: ""
    },
    questionOrder: {
      type: Number,
      default: 1
    },
    questionCount: {
      type: Number,
      default: 0
    },
    examTimeSeconds: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showEndDialog: false,
      showQuestionsDialog: false,
      remainExamTimeSeconds: 0,
      remainInterval: null,
      currentZoom: 1,
      question: {
        title: "",
        options: [
          { order: 1, letter: "A", content: "NO CHANGE" },
          { order: 2, letter: "B", content: "does make" },
          { order: 3, letter: "C", content: "is making" },
          { order: 4, letter: "D", content: "makes" }
        ],
        crossOutArr: [],
        maskArr: []
      },
      step: 0,
      forward: Math.ceil(($(window).width() - 200) / 62) * 31
    };
  },
  computed: {
    instant() {
      return instant;
    }
  },
  watch: {
    currentZoom() {
      $(".wrapper").animate({ zoom: this.currentZoom }, "slow");
    },
    questionOrder() {
      this.setPageStyle();
    },
    sectionOrder() {
      this.remainExamTimeSeconds = this.examTimeSeconds;
    },
    page(value) {
      if (value === 2) {
        this.startReciprocal();
      }
    }
  },

  created() {
    this.remainExamTimeSeconds = this.examTimeSeconds;
    if (this.page === 2) {
      this.startReciprocal();
      this.setPageStyle();
    }
  },

  methods: {
    //前后翻页
    changePage(type) {
      switch (type) {
        case "back":
          this.$emit("onBackPage");
          break;
        case "next":
          this.$emit("onNextPage");
          break;
      }
    },
    //前后切换题目
    changeQuestion(type) {
      switch (type) {
        case "back":
          this.$emit("onBackQuestion", {
            questionSecondsSpent: 1,
            testTime: this.remainExamTimeSeconds
          });
          break;
        case "next":
          this.$emit("onNextQuestion", {
            questionSecondsSpent: 1,
            testTime: this.remainExamTimeSeconds
          });
          break;
      }
      this.questionSecondsSpent = 0;
      this.setPageStyle();
    },
    //下一个单元
    submitSection() {
      clearInterval(this.remainInterval);
      clearInterval(this.questionSecondsSpentInterval);
      this.$emit(
        "onSubmitSection",
        this.questionSecondsSpent,
        this.remainInterval,
        this.questionSecondsSpentInterval
      );
      this.questionSecondsSpent = 0;
      this.showEndDialog = false;
    },
    getUnansweredCount() {
      let count = 0;
      this.questions.forEach(question => {
        if (!question.answer) {
          count++;
        }
      });
      return count;
    },
    //选择题目
    changeQuestionWithOrder(order) {
      this.$emit("onOrderQuestion", order, {
        questionSecondsSpent: 1,
        testTime: this.remainExamTimeSeconds
      });
      this.setPageStyle();
    },
    clickRow(row, column, event) {
      this.changeQuestionWithOrder(row.order);
      this.showQuestionsDialog = false;
    },
    //开始考试
    startReciprocal() {
      if (this.remainInterval) {
        clearInterval(this.remainInterval);
      }
      this.remainExamTimeSeconds = this.examTimeSeconds;
      this.remainInterval = setInterval(() => {
        this.remainExamTimeSeconds -= 1;
        if (this.remainExamTimeSeconds === 0) {
          clearInterval(this.remainInterval);
          clearInterval(this.questionSecondsSpentInterval);
          this.$emit(
            "onSubmitSection",
            this.questionSecondsSpent,
            this.remainInterval,
            this.questionSecondsSpentInterval
          );
          this.questionSecondsSpent = 0;
        }
      }, 1000);
    },
    // startSecondsSpentInterval() {
    //   if (this.questionSecondsSpentInterval) {
    //     clearInterval(this.questionSecondsSpentInterval);
    //   }
    //   this.questionSecondsSpentInterval = setInterval(() => {
    //     this.questionSecondsSpent += 1;
    //   }, 1000);
    // },
    //选择选项
    onChangeOption(letter) {
      console.log(letter);
      if (this.canEliminator) {
        let index = this.question.crossOutArr.indexOf(letter);
        if (index === -1) {
          this.question.crossOutArr.push(letter);
        }
        if (index > -1) {
          this.question.crossOutArr.splice(index, 1);
        }
      }
      if (this.canMasking) {
        let index = this.question.maskArr.indexOf(letter);
        if (index === -1) {
          this.question.maskArr.push(letter);
        }
        if (index > -1) {
          this.question.maskArr.splice(index, 1);
        }
      }
    },
    onChangeFlag() {
      this.$emit("onChangeFlag");
    },
    //设置页码
    setPageStyle() {
      this.$nextTick(() => {
        var offset =
          $(".pagination .active").offset().left -
          $(".pagination li:first-child").offset().left;
        if (offset > $(".pagination").width() * 0.95) {
          $(".pagination").scrollLeft(offset);
          $(".flowright").attr("disabled", false);
          $(".flowright").removeClass("is-disabled");
          this.step = offset;
        }
        var offset_first = $(".pagination li:first-child").offset().left;
        var offset_pagi = $(".pagination").offset().left;
        var offset_flowleft = $(".flowleft").offset().left;
        var offset_last = $(".pagination li:last-child").offset().left;

        if (offset_first - offset_pagi == 0) {
          $(".flowright").attr("disabled", true);
          $(".flowright").addClass("is-disabled");
        }
        if (
          offset_flowleft - offset_last < 37 &&
          offset_flowleft - offset_last > 0
        ) {
          $(".flowleft").attr("disabled", true);
          $(".flowleft").addClass("is-disabled");
        }
      });
    },
    // 左按钮页码
    questionLeft() {
      this.step = this.step + this.forward;
      $(".flowright").attr("disabled", false);
      $(".flowright").removeClass("is-disabled");
      $(".pagination").scrollLeft(this.step);
      if (
        $(".flowleft").offset().left -
          $(".pagination li:last-child").offset().left >
          0 &&
        $(".flowleft").offset().left -
          $(".pagination li:last-child").offset().left <
          37
      ) {
        $(".flowleft").addClass("is-disabled");
        $(".flowleft").attr("disabled", true);
      }
    },
    questionRight() {
      this.step = this.step - this.forward;
      $(".flowleft").attr("disabled", false);
      $(".flowleft").removeClass("is-disabled");
      $(".pagination").scrollLeft(this.step);
      if (
        $(".pagination").offset().left -
          $(".pagination li:first-child").offset().left ==
        0
      ) {
        $(".flowright").addClass("is-disabled");
        $(".flowright").attr("disabled", true);
      }
    },
    setHighlighter() {
      this.$emit("onCanHighlighter");
    },
    // 设置划线
    setEliminator() {
      this.$emit("onCanEliminator");
    },
    //设置隐藏
    setMasking() {
      this.$emit("onCanMasking");
    },
    //放大
    zoomIn() {
      this.currentZoom += 0.1;
    },
    //缩小
    zoomOut() {
      this.currentZoom -= 0.1;
    },
    //还原
    zoomRefresh() {
      this.currentZoom = 1;
    }
  }
};
</script>
<style scoped src="@/views/act/assets/css/act.css"></style>
<style scoped src="@/views/act/assets/css/simplebar.css"></style>
<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
.wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  flex-direction:column;
  justify-content:space-between; 
}
.act-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
}
.top-header {
  position: unset;
}
.content {
  flex: 1;
  overflow: auto;
  padding-bottom: 80px;
}
.footer {
  /* position: unset; */
}

.form-check {
  position: relative;
  min-height: 2rem;
}
.optionActionLine {
  position: absolute;
  display: block;
  height: 0.1rem;
  background: #000000;
  width: 104%;
  left: -2%;
}
.myquestion .form-check-input,
.myquestion .form-check-label {
  font-size: 14px;
  cursor: pointer;
}
.page-item {
  cursor: pointer;
}
.page-item.active .page-link {
  background-color: #007bff;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #007bff;
  border-color: #007bff;
}

.strike {
  text-decoration: line-through;
  color: #6c757d;
}
.form-check-hide {
  color: white;
}
.form-check-hide .strike {
  color: white;
}
::v-deep .el-table__row:hover {
  cursor: pointer;
}
.section-name {
  color: white;
  font-size: 1.5rem;
}
.pc-footer {
  display: block;
}
.mb-footer {
  display: none;
}
@media (max-width: 768px) {
  .footer {
    /* background: white; */
  }
  .pc-footer {
    display: none;
  }
  .mb-footer {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    padding: 0 1rem;
    overflow-x: auto;
  }
  .mb-pagination{
    width: 100%;
    margin: 0;
    display: flex;
    list-style: none;
    padding-left: 0;
  }
  .page-item .page-link {
    width: 40px;
    height: 40px;
    line-height: 38px;
    padding: 0;
    font-size: 1rem;
    color: #606266;
    font-weight: bold;
  }
}

</style>
